.profileContainer {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-Index: 100;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.backAnimateInProfile {
    opacity:1;    
    transition: opacity 0.5s;

}

.backAnimateOutProfile {
    opacity:0;    
    transition: opacity 0.5s;

}

#subProfileContainer {
    pointer-events: auto;
    border-radius: 5px;
    position: relative;
    justify-content: center;
    display: flex;
    width: 700px;
    background-color: white;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, .15);
}

#profileCloseIcon {
    font-size: 35px;
    color: var(--rsilienceblue);
    position: absolute;
    z-index: 100;
    left: 5px;
    top: 5px;
}

#profileCloseIcon:hover {
    color: var(--rsilienceblue-hover);
    cursor: pointer;
}
@media only screen and (max-width: 900px) {

    #subProfileContainer {
        margin: 0px;
        height: 100%;
        align-items: center;
        border-radius: 0px;
        width: 650px;
        box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, .15);
    }

    #profileCloseIcon {
        top: 8px;
        left: 8px
    }
}