.mapEmbed {
    margin-top: 15px;
    margin-bottom: 0px;
    width: 100%;
    aspect-ratio: 4/3;
    border: none;
}

.locationIcons {
    font-size: 24px;
    color: #2a8094;
    margin-right: 15px;
    margin-left: 0px;
}