.button {
    border-radius: 5px;
    color: white;
    cursor: pointer;
    border: none;
    padding: 10px;
    margin: 5px;
}

.button-large {
    font-size: larger;
}

.button-medium {
    font-size: medium;
}

.button-small {
    font-size: small;
}

.button-blue {
    background-color: var(--rsilienceblue);
}

.button-blue:hover {
    background-color: var(--rsilienceblue-hover);
}

.button-red {
    background-color: var(--destructive-red);
}

.button-red:hover {
    background-color: var(--destructive-red-hover);
}