.imageHome {
  width: 100%;
  aspect-ratio: 4/3;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.homeBasisContain {
  display: flex;
  justify-content: center;
  height: -webkit-calc(100vh - 64px);
  height: -moz-calc(100vh - 64px);
  height: calc(100vh - 64px);
}

.searchSide {
  display: flex;
  flex-basis: 50%;
  max-width: 1400px;
  flex-direction: column;
  justify-content: center;
}

.bottomSectionContainer {
  flex-basis: 50%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--rsilienceyellow);
}

.searchSide,
.bottomSectionContainer {
  margin: 5%;
}

.titleHome {
  font-size: 40px;
  margin-bottom: 70px;
  color: var(--rsilienceblue);
  font-weight: 200;
}

.titleHomeSub {
  margin-bottom: 20px;
}

.titleHomeSubSub {
  margin-top: 10px;
  color: var(--gray);
  font-size: 14px;
}

.bottomTextHeader {
  font-size: 26px;
  margin-bottom: 20px;
}

.bottomTextBody {
  font-size: 18px;
  display: flex;
  align-self: center;
}

.bottomText {
  margin-left: 0px;
  width: 100%;
  padding: 50px 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.bottomTextHeader,
.bottomTextBody {
  margin-left: 70px;
  margin-right: 70px;
}

.companyLogoLoading {
  width: 200px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background: radial-gradient(white, var(--rsilienceblue));
}

.loadingText {
  margin-left: 10px;
  margin-right: 20px;
  font-size: 25px;
  color: var(--gray);
}

.containTextLoad {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1400px) {
  .bottomTextHeader,
  .bottomTextBody {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .bottomSectionContainer {
    flex-basis: 40%;
  }

  .searchSide {
    flex-basis: 60%;
  }

  .searchSide,
  .bottomSectionContainer {
    margin: 20px;
  }

  .bottomTextHeader,
  .bottomTextBody {
    margin-left: 20px;
    margin-right: 20px;
  }

  .bottomTextHeader {
    font-size: 25px;
  }

  .bottomTextBody {
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px) {
  .homeBasisContain {
    height: auto;
    flex-direction: column;
  }

  .bottomSectionContainer {
    margin-top: 25px;
    width: 80%;
    align-self: center;
  }

  .searchSide {
    width: 80%;
    align-self: center;
  }

  .titleHome {
    margin-bottom: 55px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .homeBasisContain {
    margin: 0px;
  }

  .searchSide {
    width: 90%;
    align-self: center;
  }

  .bottomSectionContainer {
    width: 100%;
    margin-bottom: 0px;
  }

  .imageHome {
    border-radius: 0px;
  }

  .bottomText {
    border-radius: 0px;
  }
}
