.backgroundOverlayFilters {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-Index: 1000;
    pointer-events: none;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
}

.filter-container {
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, .15);
    height: 100vh;
    pointer-events: auto;
    width: 500px;
    background-color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.filtersBackAniIn {
    opacity:1;    
    transition: opacity 0.5s;

}

.filtersBackAniOut {
    opacity:0;    
    /* transition: opacity 0.5s; */

}

.filtersAniIn {
    transition: transform 0.23s ease-in-out;
    transform: translateX(0px);

}

.filtersAniOut {
    transition: transform 0.20s ease-in-out;
    transform: translateX(520px);
}

#filter-content {
    padding-left: 25px;
    padding-right: 25px;
    /* padding-bottom: 130px;
    padding-bottom: 25px; */
    overflow: auto;
}

#filter-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #707070;
    padding: 21.5px 21.5px;
    font-size: 16px;
}

.filter-header-button {
    font-weight: bold;
    color: #2a8094;
    margin: 0;
}

.filter-header-button:hover {
    cursor: pointer;
    color: #1F4267;
}

#filter-header-results-label {
    font-weight: bold;
    margin: 0;
}

.filter-heading {
    font-weight: bold;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.checkbox-container {
    font-size: 16px;
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    padding-top: 15px;
}

.uncheckedIcon {
    border: 1px solid #707070;
    border-radius: 2px;
    background-color: #f6f6f6;
    margin-right: 10px;
    font-size: 20px;
    color: #f6f6f6;
}

.dropWarning {
    font-size: 15px;
    color: #707070;
    margin-top: 10px;
}

.dropWarningIcon {
    margin-right: 5px;
    font-size: 18px;
}

.checkedIcon {
    border: 1px solid #2a8094;
    border-radius: 2px;
    background-color: #2a8094;
    margin-right: 10px;
    font-size: 20px;
    color: white;
}

.checkbox-container:hover .checkedIcon {
    background-color: #1F4267;
}

.checkbox-container:hover .uncheckedIcon {
    background-color: #B1B1B1;
    color: white;
    border-color: #B1B1B1;
}

@media only screen and (max-width: 500px) {

    #filter-container {
        width: 100%;
        border-radius: 0px;
        height: 100vh;
    }

}