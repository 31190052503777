.imagePickImage {
    width: 50%;
    aspect-ratio: 4/3;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    outline: 1px solid #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    margin-bottom: 50px;
}

.image-pick-container {
    display: flex;
    align-items: center;
}

.imagePickImageError {
    outline: 1px solid red;
}

.noPhotoUploaded {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #707070;

}

.iconImgPick {
    margin-bottom: 8px;
    font-size: 30px;
    color: #707070
}

.fileSelectButton {
    background-color: #2a8094;
    color: white;
    width: 50%;
    padding: 8px 0px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 2px;

}

.fileSelectButton:hover {
    background-color: #1F4267;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {

    .imagePickImage {
        width: 70%;
    }

    .fileSelectButton {
        width: 70%;
    }

}