.backgroundOverlay {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-Index: 1;
    overflow: hidden;
    pointer-events: none;
}

.containerSection {
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, .15);
    height: 100vh;
    /* max-width: 1224px; */
    /* fits width of picture if needed, has max width for larger images */
    width: fit-content;
    max-width: 850px;
    margin: auto;
    background-color: white;
    pointer-events: auto;
}

.splitContain {
    position: relative;
    top: 0;
    display: flex;
}

/* .imageSide {
    flex-basis: 60%;
    height: 100vh;
    overflow: auto;
} */

.infoSideInnerContain {
    overflow: auto;
}

.infoSide {
    /* flex-basis: 60%; */
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.infoIcons {
    font-size: 28px;
    color: var(--rsilienceblue);
    margin-right: 15px;
    margin-left: 0px;
}

.prohibit {
    margin-right: 13px;
}

.iconProhibit {
    margin-right: 20px;
    font-size: 18px;
    position: relative;
    margin-left: -36px;
    margin-top: 5px;
}

/* .imageScroll {
    display: none;
} */

.basicPointIcons {
    font-size: 24px;
    color: var(--rsilienceblue);
    margin-right: 15px;
    margin-left: 0px;
}

.paddingPoints {
    padding-right: 20px;
    padding-left: 20px;
}

.houseFadeIn {
    opacity:1;    
    transition: opacity 0.35s;
}

.houseFadeOut {
    opacity:0;    
}

.houseControls {
    height: 75px;
    width: 100%;
    margin-top: 0px;
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1.7px solid #aaa;
}

.admin-approvebutton {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.admin-approvebutton svg {
    stroke-width: 2.5px;
    width: 32px;
    height: 32px;
}

#accept {
    color: green
}

#deny {
    color: var(--destructive-red)
}


@media only screen and (max-width: 1000px) {

    /* .containerSection {
        max-width: 650px;
    } */

    /* .imageScroll {
        display: inline;
    }

    .imageSide {
        display: none;
    } */

    /* .infoSide {
        flex-basis: 100%;
    } */

}