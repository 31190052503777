.formHeader {
    height: 63px;
    position: sticky;
    top: 0px;
    background-color: white;
    border-bottom: 1px solid #707070;
    max-width: 880px;
    margin: auto;
    z-index: 105;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backIconEditHouse {
    font-size: 35px;
    color: #2a8094;
    padding-left: 5px;
}

.backIconEditHouse:hover {
    color: #1F4267;
    cursor: pointer;
}

.backIconEditHouseWhite {
    font-size: 35px;
    color: white;
    pointer-events: none;
    padding-right: 10px;
}