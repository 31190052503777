.containerTitle {
    display: flex;
    align-items: center
}

.title {
    font-size: 20px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 20px;
    position: sticky;
    top: 0;
}

.backIcon {
    font-size: 35px;
    padding-left: 10px;
    color: #2a8094;
    display: none;
}

.backIcon:hover {
    color: #1F4267;
    cursor: pointer;
}

.closeIcon {
    font-size: 35px;
    padding-left: 10px;
    color: #2a8094;
}

.closeIcon:hover {
    color: #1F4267;
    cursor: pointer;
}

@media only screen and (max-width: 1224px) {

    .closeIcon {
        display: none;
    }

    .backIcon {
        display: flex;
    }

}

@media only screen and (max-width: 1000px) {

    .closeIcon {
        display: flex;
    }

    .backIcon {
        display: none;
    }

}

@media only screen and (max-width: 650px) {

    .closeIcon {
        display: none;
    }

    .backIcon {
        display: flex;
    }

}