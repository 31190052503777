.textHome {
  width: fit-content;
  color: var(--gray);
}

.signInText {
  margin-right: 20px;
}

.brandingHeader {
  display: flex;
  margin-left: 15px;
  text-decoration: none;
}

.brandingHeader:active {
  color: var(--rsilienceblue);
}

.brandNameHeader {
  font-size: 18px;
  margin: 0px 0px 0px 15px;
  align-self: center;
  color: var(--rsilienceblue);
  font-weight: 500;
}

.brandingHeaderImage {
  height: 55px;
}

.headerWrapper {
  position: sticky;
  top: 0px;
}

.homeHeader {
  height: var(--header-height);
  position: sticky;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: white;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1.5px solid #424;
}

.signUpButton {
  padding: 8px 20px;
  color: white;
  font-family: "Nunito";
  border-radius: 35px;
  background-color: var(--rsilienceblue);
}

.signUpButton:hover {
  background-color: var(--rsilienceblue-hover);
  cursor: pointer;
}

#menuButtonIcon {
  font-size: 35px;
  color: var(--rsilienceblue);
  margin-left: 89.63px;
  stroke-width: 1.5;
}

#menuButtonIcon:hover {
  color: var(--rsilienceblue-hover);
  cursor: pointer;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.5s;
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.5s;
}

.authConfirm {
  background-color: #404040;
  color: white;
  position: fixed;
  padding: 10px 15px;
  bottom: 15px;
  left: 50%;
  border-radius: 5px;
  transform: translate(-50%, 0);
  text-align: center;
}

.signUpOnly {
  display: none;
}

@media only screen and (max-width: 800px) {
  .signInText {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .signInText {
    display: none;
  }

  .addMy {
    display: none;
  }

  .signUpOnly {
    display: flex;
  }
}
