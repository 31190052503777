#confirmButton {
    padding: 15px 15px;
    background-color: var(--rsilienceblue);
    color: white;
    margin-top: 30px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    font-size: medium;
    width: 100%;
}

#confirmButton:hover {
    background-color: var(--rsilienceblue-hover);
    cursor: pointer;
}

.signUpTagline {
    padding-top: 10px;
    font-weight: normal;
    font-size: 15px;
    color: var(--gray);
}

#authForm {
    max-width: 400px;
    margin: auto;
}

.warning {
    margin-top: 26px;
    margin-bottom: 20px;
    font-size: 15px;
    color: var(--gray);
}

.forgot {
    margin-top: 8px;
    margin-bottom: 0px;
    color: var(--rsilienceblue);
    font-size: 15px;
}

.forgotText {
    width: fit-content;
}

.forgotText:hover {
    color: var(--rsilienceblue-hover);
    cursor: pointer;
}

.emailError {
    margin-top: -10px;
}

.error {
    color: red;
    display: flex;
    align-items: center;
}

.hideEmailError {
    display: none;
}

.errorIconsAuth {
    font-size: 18px;
    margin-right: 5px;
}

.emailSentTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.authTitles {
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 22px;
    color: var(--rsilienceblue);
}

@media only screen and (max-width: 900px) {

    .authTitles {
        margin-top: 0px;
        font-size: 19px;
    }

    #authForm {
        max-width: 1000px;
        width: 90%;
    }

    #confirmButton {
        margin-bottom: 58px;
    }

}