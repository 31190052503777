.locationScreenButtons {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-around;
  align-content: center;
  border-bottom: 1px solid #c1c1c2;
  border-top: 1px solid #c1c1c2;
  background-color: white;
  position: sticky;
  top: 0;
}

.linkStyle {
  text-decoration: none;
  color: black;
  border: none;
}

.linkStyle:active {
  color: black;
}

.locationScreenButton {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2a8094;
  cursor: pointer;
  font-weight: bold;
}

.locationScreenButton:hover .iconButton {
  color: #1f4267;
}

.locationScreenButton:hover {
  color: #1f4267;
  cursor: pointer;
}
