.textareaInput {
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f6f6f6;
    border: 1px solid #707070;
    border-radius: 4px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    resize: none;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0px;
}

.charaCount {
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: -20px;
    font-weight: bold;
}

.textareaInput:focus {
    outline: none;
    border: 2px solid #2a8094;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 9px;
    padding-right: 9px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.textareaInput::placeholder {
    font-size: 16px;
    color: transparent;
}


/* .labelBackgroundTextarea {
    color: transparent;
    background-color: #f6f6f6;
    position: absolute;
    font-size: 16px;
    z-index: -5;
    top: 10.4px;
} */

.containerTextArea {

    z-index: 100;

    padding: 0px;
    margin:0px;
    position: absolute;

    /* bottom:0px;
    left: 11px;
    right:0px;
    top:55px;  */
    top:0px;

    transform: translateY(290%) scale(1);

    transform-origin: 0 0;
    transition: transform 0.21s ease-in-out;
    color: #707070;
    pointer-events: none;
}

.textareaLabel {
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.containerWholeTextArea {
    position: relative;
}

.textAreaBackgroundColorAni, .textareaInput:focus+.containerTextArea,
.textareaInput:not(:placeholder-shown)+.containerTextArea {
    transform: translateY(-40%) scale(0.8);
}

.textareaInput:focus+.containerTextArea {
    color: #2a8094;
}

.textAreaBackgroundColorAni {
    background-image: linear-gradient(180deg, white 50%, #f6f6f6 50%);
    z-index: 1;
    color: transparent;

    margin-left: 4px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;

    opacity: 0;

}

.textAreaBackgroundColorAniGo {

    opacity: 1;
    -webkit-transition: opacity .07s ease-in;
    transition: opacity .07s ease-in;
-webkit-transition-delay: .13s;
    transition-delay: .13s;

}