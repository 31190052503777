.pagecontainer {
    min-height: 100vh;
    width: 100vw;
    background-color: var(--rsilienceblue);
}

.surveyContainer {
    min-width: 310px;
    width: 90%;
    max-width: 600px;
    padding: 20px 25px 30px;
    background-color: white;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, .15);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10000;
}

.surveyHeader {
    font-size: 24px;
}

.surveysub {
    margin-top: -24px;
}

.surveyQ {
    width: 100%;
}

.checkbox {
    margin-bottom: 4px;
}

.checkbox > label > span {
    margin-left: 2px;
}

#surveySlider {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    margin: 0px;
}

.buttonContainer {
    width: 45px;
    min-height: 45px;
    margin-right: 7px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 14px;
}

.buttonContainer > p {
    margin-top: 5px;
}

.sliderNum {
    font-size: 24px;
    border: none;
    color: black;
    cursor: pointer;
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    border: 1px solid #999;
}

.sliderNum:hover {
    background-color: #ddd;
}

.selected {
    background-color: var(--rsilienceblue);
    color: #eee;
    border: 1px solid #ddd;
}

.selected:hover {
    background-color: var(--rsilienceblue-hover);
}

.rankings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10px;
}

.numRank {
    width: 40px;
    margin: -20px 0px 0px 0px;
    text-align: center;
}

.dismiss {
    border: none;
    background-color: rgba(0,0,0,0);
    font-size: 16px;
    margin: 2px;
    color: var(--rsilienceblue);
}

.dismiss:hover {
    text-decoration: underline;
    color: var(--rsilienceblue-hover);
    cursor: pointer;
}