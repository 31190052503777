#locationSearchBarContain {
    position: fixed;
    z-index: 1;
    top: 0px;
    width: 100%;
}

.searchResultsContainer {
    margin-top: 121px;
    margin-bottom: 50px;
    margin-left: 30px;
    margin-right: 30px;
}

#locationScreenContainSearch {
    position: relative;
    z-index: 100000;
}
