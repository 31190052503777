#header-container {
    border-bottom: 1px #707070 solid;
    padding: 6px 20px;
    padding-left: 5px;
    background-color: white;
}

.formSearch {
    position: relative;
    width: 40%;
}

.homeScreenFormSearch {
    position: relative;
    width: -webkit-calc(100% - 52px);
    width:    -moz-calc(100% - 52px);
    width:         calc(100% - 52px);
}

.searchTextBox {
    font-size: 16px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 35px;
    background-color: #f6f6f6;
    border: 1px solid #707070;
    border-radius: 4px;
    font-family: inherit;
}

.filterButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 70px;
}

.filterButtonText {
    font-weight: bold;
    color: var(--rsilienceblue);
}

.filterButtonIconFilter,
.filterButtonIconMenu {
    font-size: 23px;
    color: var(--rsilienceblue);
}

.filterButtonIconFilter {
    display: none;
}

.filterButton:hover {
    cursor: pointer;
}

.filterButton:hover .filterButtonText,
.filterButton:hover .filterButtonIconMenu,
.filterButton:hover .filterButtonIconFilter {
    color: var(--rsilienceblue-hover);
}

#search-text-box:focus {
    /* outline-color: #2a8094; */
    outline: none;
    border: 2px solid var(--rsilienceblue);
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 14px;
    padding-right: 34px;
}

.searchIcon {
    font-size: 24px;
    position: absolute;
    top: 8px;
    right: -45px;
    color: #707070;
}

.searchIcon:hover {
    cursor: pointer;
}

.use-current {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid var(--rsilienceblue);
    background-color: #f6f6f6;
    font-size: 16px;
    top: 36px;
    width: 100%;
    padding-left: 11px;
    height: 50px;
    position: absolute;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-right: 37px;
}



.locationIcon {
    color: var(--rsilienceblue);
    font-size: 19px;
}

.useCurrText {
    margin-left: 8px;
}

.loading {
    color: #585858
}

.loadingCurr {
    background-color: #CECECE;
    border: 2px solid #CECECE;
    border-top: 2px solid var(--rsilienceblue);
}

.loadingCurr:hover {
    cursor: default;
}

.searchAndFilterButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.backButtonLocationSearch {
    margin-right: 10px;
    display: flex;
    justify-content: center;
}

.backIconLocationSearch {
    font-size: 35px;
    color: #2a8094;
}

.backIconLocationSearch:hover {
    color: #1F4267;
    cursor: pointer;
}

/*.inactiveUseCurrent{
    cursor: wait;
}*/

@media only screen and (max-width: 850px) {

    .formSearch {
        width: 60%;
    }

}

@media only screen and (max-width: 600px) {

    .filterButtonIconFilter {
        display: flex;
    }

    .filterButtonIconMenu {
        display: none;
    }

}



