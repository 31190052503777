.authContainer {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-Index: 100;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.authFadeIn {
    opacity:1;    
    transition: opacity 0.5s;
}

.authFadeOut {
    opacity:0;    
} 


#subAuthContainer {
    border-radius: 5px;
    pointer-events: auto;
    position: relative;
    display: flex;
    width: 850px;
    background-color: white;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, .15);
}

#decorationAuth {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 24px;
    flex-basis: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e3ebf8;
    font-weight: bold;
    color: var(--rsilienceblue);
    flex-direction: column;
}

.tagLine {
    font-size: 17px;
    font-weight: normal;
    text-align: center;
    padding: 10px 15px;
}

#signUpContinerAuth {
    padding-bottom: 0px;
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
}

#signUpPadding {
    padding-top: 0px;
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 0px;
}

#authCloseIcon {
    font-size: 35px;
    color: var(--rsilienceblue);
    position: absolute;
    z-index: 100;
    left: 5px;
    top: 5px;
}

.navAway {
    color: var(--rsilienceblue);
    border: none;
    font-size: 15px;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid var(--gray);
}

.navAwayText {
    width: fit-content;
}

#authCloseIcon:hover,
.navAwayText:hover {
    color: var(--rsilienceblue-hover);
    cursor: pointer;
}

#decorationAuthResp {
    display: none;
}

@media only screen and (max-width: 900px) {

    #subAuthContainer {
        margin: 0px;
        height: 100%;
        align-items: center;
        border-radius: 0px;
        width: 650px;
        box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, .15);
    }

    .navAway {
        position: fixed;
        width: 650px;
        bottom: 0;
    }

    #signUpPadding {
        padding-right: 0px;
        padding-left: 0px;
    }

    #authCloseIcon {
        top: 0px;
        bottom: 0px;
    }

    #decorationAuth {
        display: none;
    }

    #signUpContinerAuth {
        flex-basis: 100%;
    }

    #decorationAuthResp {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-weight: bold;
        color: var(--rsilienceblue);
        font-size: 24px;
        margin-bottom: 25px;
    }

    #authCloseIcon {
        top: 8px;
        left: 8px
    }
}

@media only screen and (max-width: 650px) {
    .navAway {
        width: 100%;
    }

}