#NavMenuContain {
    height: 100vh;
    background-color: white;
    position: absolute;
    z-index: 1000;
}

.backgroundOverlayNavMenu {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-Index: 100;
    pointer-events: none;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
}


.menuAnimationIn {
    transition: transform 0.23s ease-in-out;
    transform: translateX(0px);

}

.menuAnimationOut {
    transition: transform 0.20s ease-in-out;
    transform: translateX(320px);

}

.backAnimateIn {
    opacity:1;    
    transition: opacity 0.5s;

}

.backAnimateOut {
    opacity:0;    
    /* transition: opacity 0.5s; */

}

.containerSectionNavMenu {
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, .15);
    height: 100vh;
    pointer-events: auto;
    width: 300px;
    background-color: white;
    overflow: auto;
}

.navIcon {
    color: #2a8094;
    font-size: 30px;
    padding-right: 8px;
}

#closeNavIcon {
    color: #2a8094;
    font-size: 30px;
    padding-left: 10px;
    padding-top: 10px;

}

#closeNavIcon:hover {
    cursor: pointer;
    color: #1F4267;
}

#navMenuSignOutDesktop {
    position: absolute;
    bottom: 0px;
    background-color: white;
    width: 100%;
    border-top: 1px solid #707070;
}

#navMenuSignOutMobile {
    display: none;
}

.signOutIcon {
    padding-top: 5px;
    padding-bottom: 5px;
}

#navMenuSignOutDesktop:hover { 
    cursor: pointer;
    background-color: #E7E7E7;
}

@media only screen and (max-width: 550px) {
    
    #navMenuSignOutDesktop {
        display: none;
    }

    #navMenuSignOutMobile {
        display: flex;
    }

}

@media only screen and (max-width: 300px) {
    
    .containerSectionNavMenu {
        width: 100%;
    }

}