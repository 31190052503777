.form-header {
    font-weight: bold;
    font-size: 24px;
    color: var(--rsilienceblue);
    border-bottom: 5px solid var(--rsilienceblue);
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 30px;
    margin-left: -40px;
    margin-right: -40px;
}

.wholeForm {
    overflow: hidden;
}

.backgroundForm {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    overflow: hidden;
    pointer-events: none;
}

.formEle {
    /* z-index: 8; */
    position: relative;
    overflow: hidden;
    background-color: white;
    padding: 40px;
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.form-subtext p {
    font-style: italic;
    margin: 7px 0px;
    padding: 0px;

}

.submitForm {
        padding: 15px 15px;
        font-family: inherit;
        background-color: var(--rsilienceblue);
        color: white;
        margin-top: 30px;
        border: none;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-self: center;
        font-size: 16px;
        width: 100%;
}

.deleteHouse {
    background-color: var(--destructive-red);
}

.submitForm:hover {
    background-color: var(--rsilienceblue-hover);
    cursor: pointer;
}

.deleteHouse:hover {
    background-color: var(--destructive-red-hover);
}

.loadscreen{
    color: white;
    font-size: 30px;
    font-weight: bold;
}

.loadIcon {
    margin-right: 20px;
}


@media only screen and (max-width: 500px) { 

    .formEle {
        padding: 40px 15px;
    }


}

.uploadingContainer {
    justify-content: center;
    align-items: center;
    height: 100vh;
}