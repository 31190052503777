.imagesBack {
    width: 100%;
    aspect-ratio: 4/3;
    scroll-behavior: smooth;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.imageScrollContain::-webkit-scrollbar {
    display: none;
}

.imageScrollContain {
    scroll-snap-type: x mandatory;
    scroll-margin: 10px;
    scroll-snap-stop: normal;
    position: relative;
    width: inherit;
}

.subImageScrollContain {
    display: flex;
    resize: contain;
}

.indicationDot {
    font-size: 9px;
    margin-left: 5px;
    margin-right: 5px;
    border: 1px solid #2a8094;
    border-radius: 100px;
    color: transparent;
}

.dotWhiteContainer {
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    padding: 3px;
    border-radius: 20px;
}

.indicationDot:hover {
    color: #2a8094;
    font-size: 11px;
    border: none;
}

.dotContainer {
    display: flex;
    justify-content: center;
    margin-left: 60px;
    margin-right: 60px;
    position: relative;
    bottom: 81px;
    z-index: 100;
    align-self: flex-end;
}

.imageNavContain {
    z-index: 1000;
    position: relative;
    /*position: absolute;*/
    display: flex;
    justify-content: space-between;
    margin-left: 7px;
    margin-right: 7px;
    bottom: 50px;
    /*bottom: 50%*/
}

.arrow {
    font-size: 30px;
    color: #2a8094;
    opacity: 1;

}

.arrowColor {
    color: white;
    opacity: 0.8;
    font-size: 30px;
    position: relative;
    z-index: -10;
    margin-left: -35px
}

.arrow:hover+.arrowColor {
    color: #2a8094;
    opacity: 0.8;
    /*https://stackoverflow.com/questions/6910049/on-a-css-hover-event-can-i-change-another-divs-styling*/
}

.arrow:hover {
    color: white;
}