.titleAndSubtitle {
    margin: 20px;
    margin-bottom: 30px;
}

.successPop {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 450px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    z-index: 200;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, .15);
}

.titleSucce {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.subTitleSucce {
    text-align: center;
    margin-top: 15px;
}

.buttonContainerSuccess {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.backgroundPopFadeIn {
    opacity:1;    
    transition: opacity 0.35s;
}

.backgroundPopFadeOut {
    opacity:0;
    transition: opacity 0.35s;    
} 
 
.paddingAndBorder {
    width: 100%;
    justify-content: center;
    display: flex;
    border-top: 1px solid #707070;
}

.backgroundAlert {
    color: black;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 200;
    overflow: hidden;
    pointer-events: none;
}

@media only screen and (max-width: 500px) { 
    .successPop {
        margin-left: 20px;
        margin-right: 20px;
    }
}