.backgroundAlert {
    background-color: rgba(0, 0, 0, 0.5);
    inset: 0;
    position: fixed;
    z-index: 300;
}

.popContent {
    position: absolute;
    left: 50%;
}

.backgroundPopFadeIn {
    opacity: 1;   
    transition: opacity 0.35s;
    pointer-events: all;
}

.backgroundPopFadeOut {
    opacity: 0;
    transition: opacity 0.35s;
    pointer-events: none;
} 

.containerloadSuccess {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2000;
    overflow: hidden;
    justify-content: center;
    display: flex;
}