.inputForm {
    font-size: 16px;
    padding: 15px;
    background-color: #f6f6f6;
    border: 1px solid #707070;
    border-radius: 4px;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0px;
}

.inputForm:focus {
    outline: none;
    /* box-shadow: 0 0 0 2px #2a8094; */
    border: 2px solid #2a8094;
    padding: 14px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.inputForm::placeholder {
    font-size: 16px;
    padding: 0px;
    color: transparent;
}

.labelBackground {
    background-color: #f6f6f6;
    color: transparent;
    position: absolute;
    /* bottom: -10.4px; */
    font-size: 16px;
    z-index: -5;
}

.labelAndBackgroundContainer {
    pointer-events: none;
    position: absolute;

    top: 0px;
    z-index: 100;

    /* left: 11px;
    bottom: 17.5px; */
    padding: 0px;
    margin:0px;
    transform-origin: 0 0;
    transition: transform 0.16s ease-in-out;

    transform: translateY(83%) scale(1);

    color: #707070;
}

.inputContainer {
    position: relative;
}

.backgroundColorAni, .inputForm:focus+.labelAndBackgroundContainer,
.inputForm:not(:placeholder-shown)+.labelAndBackgroundContainer {
        transform: translateY(-40%) scale(0.8);
}

.backgroundColorAni{
    background-image: linear-gradient(180deg, white 50%, #f6f6f6 50%);
    z-index: 1;
    color: transparent;

    margin-left: 4px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;

    opacity: 0;

}

.backgroundColorAniGo {

    opacity: 1;
    -webkit-transition: opacity .05s ease-in;
    transition: opacity .05s ease-in;
-webkit-transition-delay: .1s;
    transition-delay: .1s;

}

.inputLabel {
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.inputForm:focus+.labelAndBackgroundContainer {
    color: #2a8094; 
}



