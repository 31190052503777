.results {
    /* margin: auto;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    row-gap: 50px;
    column-gap: 0px;
    margin: 20px; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px
}


@media only screen and (max-width: 1300px) {
    .results {
        grid-template-columns: 1fr 1fr;
    }

}
    
    @media only screen and (max-width: 850px) {
        .results {
            grid-template-columns: 1fr;
            margin: 0px 50px;
        }
    }
    
    @media only screen and (max-width: 600px) {
    
        .results {
            margin: 0px;
        }
    
    }