.dropForm {
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f6f6f6;
    border: 1px solid #707070;
    border-radius: 4px;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0px;
    font-family: inherit;

    /* grid-column: 1;
    grid-row: 1; */
}

.dropForm:focus {
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.dropForm::placeholder {
    font-size: 16px;
    color: transparent;
}

/* .dropLabelBackground {
    background-color: #f6f6f6;
    color: transparent;
    position: absolute;
    bottom: 5px;
    font-size: 16px;
    z-index: -5;
    height: 3px;

} */

.dropLabel {
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    /* background-image: linear-gradient(180deg, white 50%, #f6f6f6 50%); */
    
    /* transition-delay: 250ms;transition-property: background-image; */
    /* -webkit-transition: background-image 12s ease-in;
            transition: background-image 12s ease-in;
    -webkit-transition-delay: 12s;
            transition-delay: 12s; */
}

.dropLabelFirst {
    padding-right: 5px;
    padding-left: 5px;
    margin-left: 5px;
    font-size: 16px;
    /* background-image: transparent;
    background-color:  #f6f6f6;
        -webkit-transition: background-color .1s ease-in;
            transition: background-color .1s ease-in;
    -webkit-transition-delay: 2.1s;
            transition-delay: 2.1s; */
}

.blockLine {
    margin-left: 4px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;

    background-image: linear-gradient(180deg, white 50%, #f6f6f6 50%);
    position: absolute;
    z-index: 1;
    color: transparent;
    top:0;
    font-size: 16px;
    transform-origin: 0 0;
    transform: translateY(-40%) scale(0.8);
    opacity: 0;
}


.blockLineAni {
    opacity: 1;
    -webkit-transition: opacity .05s ease-in;
    transition: opacity .05s ease-in;
-webkit-transition-delay: .1s;
    transition-delay: .1s;
}

.dropLabelAndBackgroundContainerFirst {
    pointer-events: none;

    position: absolute;
    top: 0px;
    z-index: 2;
    /* bottom: 16.8px;
    left: 11px; */

    /* grid-column: 1;
    grid-row: 1; */

    padding: 0px;
    margin:0px;
    color: #707070;
    transform-origin: 0 0;
    transition: transform 0.16s ease-in-out;
    transform: translateY(83%) scale(1);
}

.dropLabelAndBackgroundContainerAfterFirst {
    /* transform: translateY(-50%) scale(0.8); */
        transform: translateY(-40%) scale(0.8);
        /* top: 33%; */


}

.containerDrop {
    position: relative;
    margin-top: 6px;
}

.iconDropContain {
    position: absolute;
    background-color: #f6f6f6;
    top: 28%;
    right: 1%;
    pointer-events: none;
}

.iconDrop {
    font-size: 24px;
    color: #707070;
}