.infoPointsTitle {
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
}

.navMenuInfoPointsTitle{
    padding-left: 25px;
}

.infoPointsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
}

.infoPointsPara {
    margin-top: 10px;
    white-space: pre-wrap;
}

.navItemInfoPointsContainer {
    margin-top: 0px;
    padding: 10px 25px;
}

.navItemInfoPointsContainer:hover {
    background-color: #E7E7E7;
    cursor: pointer;
}