.house-info {
    margin-left: 8px;
    margin-right: 8px;
}

svg {
    margin-right: 5px;
}

.house-item {
    position: relative;
    /* width: 30%; */
    width: 100%;
    margin: auto;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0);
}

.house-item:hover .houseItemImage {
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, .15);
    transition: box-shadow 0.25s ease-in-out;
}

.houseItemTitle {
    font-weight: bold;
    margin-top: 10px;
    font-size: 17px;
    margin-bottom: 2px;
    text-align: left;
}


.house-info p {
    margin: 0px 0px 5px 5px;
    font-size: 16px;
    color: #707070;
    margin: 0;
    margin-left: 8px;
}

.house-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 7px;
}

.houseItemImage {
    width: 100%;
    aspect-ratio: 4/3;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
}

.editHouseMenu {
    background-color: rgba(255, 255, 255, 0.75);
    display: block;
    display: flex;
}

.editHouseIconContainer {
    padding: 30px;
    color: #2a8094;
    background-color: white;
    border: 1px solid #eee;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
}

.editHouseButton {
    background-color: #2a8094;
    color: white;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px;
    border-radius: 6px;
    cursor: pointer;
}

.editHouseButton:hover {
    background-color: #1F4267;
}

.house-info svg {
    color: #707070;
    font-size: 19px;
}

/* #circleAndBookContain {

} */

#circleAndBookContain:hover #circleIconHouse  {
    color: #1F4267;
    cursor: pointer;
}

#circleIconHouse {
    /* position: absolute;
    right: 0x; */
    font-size: 40px;
    opacity: 0.8;
    color: #2a8094;
    position: absolute;
    right:5px;
    top: 5px;
    /* margin: 10px; */
}

.outlineHouse {
    font-size: 22px;
    margin-left: -25px;
}

#doubleIconHouse {
    position: absolute;
    top: 17.5px;
    right: 17.5px;
    pointer-events: none;
}

.bookmarkIconHouse {
    position: absolute;
    padding: 7.50px;
    right:5px;
    top: 5px;
    cursor: pointer;
    font-size: 39px;
    color: white;
}

@media only screen and (max-width: 1300px) {
/* 
    .house-item {
        width: 45%;
    } */

}

@media only screen and (max-width: 850px) {

    /* .house-item {
        width: 80%;
        margin-left: 0px;
        margin-right: 0px;
    } */

}

@media only screen and (max-width: 500px) {

    /* .house-item {
        width: 100%;
    } */

}