#disclaimerContainer {
    background-color: white;
    position: fixed;
    align-items: center;
    padding: 50px;
    display: flex;
    flex-direction: column;
    z-index: 100;
    width: 600px;
    height: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 7px;
}

#disclaimerContainer > p {
    margin-bottom: 20px;
}

.appLogo-disclaimer {
    height: 100px;
    width: 100px;
}

@media screen and (max-width: 500px) {
    #disclaimerContainer {
        height: min-content;
        width: calc(100vw - 50px);
        padding: 80px 25px;
    }

    #disclaimerContainer > p {
        height: 250px;
        overflow-y: scroll;
    }

    .appLogo-disclaimer {
        height: 75px;
        width: 75px;
    }
}