/* Globally accessible fonts across the site */
div,
p,
span,
button,
input {
  font-family: "Nunito"; /* google font imported in index.js */
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Georgia";
}

.noscroll {
  overflow: hidden;
}

body {
  padding: 0px;
  margin: 0px;
}

/* 
  These are branding colors and other commonly used values
  throughout the site. These should stay consistent across the site,
  and changing a value here SHOULD change it across the whole site 
  if the CSS is set up properly using var().

  These colors are accessible in other CSS files by using
  the var() function of CSS
  @see https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
*/
:root {
  --rsilienceblue: #2a8094;
  --rsilienceblue-hover: #1f4267;

  --rsilienceyellow: #edd36a;

  --gray: #707070;

  --destructive-red: #bd2f2f;
  --destructive-red-hover: #7b1e1e;

  --header-height: 63px;
}
