.locationScreenButton {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #2a8094;
    cursor: pointer;
    font-weight: bold;
}

.locationScreenButtonDisabled{
    pointer-events: none;
    color: grey
}

.locationScreenButton:hover .icon {
    color: #1F4267;
}

.locationScreenButton:hover {
    color: #1F4267;
    cursor: pointer;
}

.icon {
    cursor: pointer;
    font-size: 20px;
    color: #2a8094;
    align-self: center;
    display: flex;
    margin-right: 10px;
}

.iconDisabled{
    pointer-events: none;
    color: grey
}


.outline {
    font-size: 22px;
    position: relative;
    margin-left: -31px;
}

.doubleIcon {
    display: flex
}