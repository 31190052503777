.basicPoints {
    margin-left: 0px;
    margin-top: 15px;
}

.basicPointContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    align-items: center;
}

.textBasicPoint {
    font-size: 16px;
}

.basicPointsTitle{
    font-weight: bold;
    font-size: 17px;
    margin-top: 35px;
}